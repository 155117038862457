<script setup>
import router from "../../../router";
import { ref, inject, onMounted, watch } from "vue";
import SidebarApp from "../../../views/inbox/components/SidebarApp.vue";
import BarChart from "../common/analytics/BarChart.vue";
import KPIs from "../common/analytics/KPIs.vue";
import { toast } from "vue3-toastify";
import { sendGrid } from "../providers/api/sendgrid";

const {
  inboxBusinessClients,
  handlePageSelected,
  updateInbox,
  inbox,
  fetchMetaInfo,
  metaInfo,
  getEmailMetrics,
  getMailboxMetadata,
} = inject("inbox");

const tags = ref([]);
const selectedCategory = ref(null);
const startDate = ref("");
const endDate = ref("");

onMounted(async () => {
  if (!inbox.value.id) {
    router.push(`/inbox/`);
  }

  if (inbox.value.id) {
    const data = await getMailboxMetadata(inbox.value.id);

    const mailingTags = data.mailing_tags ? data.mailing_tags : null;

    if (mailingTags) {
      tags.value.push(...mailingTags);
    }
  }
});

const chartData = ref({
  labels: [],
  datasets: [
    {
      label: "Aberturas",
      backgroundColor: "#ff4d4d",
      data: [],
    },
    {
      label: "Aberturas Únicas",
      backgroundColor: "#ff9999",
      data: [],
    },
  ],
});

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
});

const kpis = ref({
  requests: 0,
  delivered: 0,
  opens: 0,
  unique_opens: 0,
  clicks: 0,
  unique_clicks: 0,
  unsubscribes: 0,
  bounces: 0,
  spam_reports: 0,
  blocks: 0,
  bounce_drops: 0,
  spam_report_drops: 0,
  unsubscribe_drops: 0,
  schedulings: 0,
});

const fetchData = async () => {
  if (!startDate.value || !endDate.value || !selectedCategory.value) {
    toast("Oops! Parece que algum campo não foi selecionado!", {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "#c60d31",
      },
    });
    return;
  }

  let id;

  try {
    id = toast.loading("Buscando métricas...", {
      position: "top-right",
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
    });

    console.log("Categoria selecionada:", selectedCategory.value);
    const data = await sendGrid(
      selectedCategory.value,
      startDate.value,
      endDate.value
    );

    toast.update(id, {
      render: "Métricas buscadas com sucesso!",
      type: "success",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "green",
      },
    });

    const labels = data.map((item) => item.date);
    const opens = data.map((item) => item.stats[0].metrics.opens);
    const uniqueOpens = data.map((item) => item.stats[0].metrics.unique_opens);

    chartData.value.labels = labels;
    chartData.value.datasets[0].data = opens;
    chartData.value.datasets[1].data = uniqueOpens;

    const newKpis = {
      requests: 0,
      delivered: 0,
      opens: 0,
      unique_opens: 0,
      clicks: 0,
      unique_clicks: 0,
      unsubscribes: 0,
      bounces: 0,
      spam_reports: 0,
      blocks: 0,
      bounce_drops: 0,
      spam_report_drops: 0,
      unsubscribe_drops: 0,
    };

    data.forEach((item) => {
      const metrics = item.stats[0].metrics;
      newKpis.requests += metrics.requests;
      newKpis.delivered += metrics.delivered;
      newKpis.opens += metrics.opens;
      newKpis.unique_opens += metrics.unique_opens;
      newKpis.clicks += metrics.clicks;
      newKpis.unique_clicks += metrics.unique_clicks;
      newKpis.unsubscribes += metrics.unsubscribes;
      newKpis.bounces += metrics.bounces;
      newKpis.spam_reports += metrics.spam_reports;
      newKpis.blocks += metrics.blocks;
      newKpis.bounce_drops += metrics.bounce_drops;
      newKpis.spam_report_drops += metrics.spam_report_drops;
      newKpis.unsubscribe_drops += metrics.unsubscribe_drops;
    });

    const { schedulings_count: schedulings } = await getEmailMetrics(inbox.value.id,
      startDate.value,
      endDate.value
    );

    newKpis.schedulings = schedulings;

    kpis.value = newKpis;

    console.log("Chart Data:", chartData.value);
    console.log("KPIs:", kpis.value);
  } catch (error) {
    console.error("Error fetching data:", error);
    alert("Failed to fetch data. Please check your API token.");
  }
};

watch(
  () => inbox.value,
  async (newValue, oldValue) => {
    const data = await getMailboxMetadata(newValue.id);

    if (!data || !data.mailing_tags || data.mailing_tags.length === 0) {
      inbox.value = oldValue;
      router.push(`/inbox/${inbox.value.id}/dashboard`);
    } else {
      await fetchMetaInfo();

      tags.value.length = 0;
      tags.value.push(...data.mailing_tags);
      kpis.value = {
        requests: 0,
        delivered: 0,
        opens: 0,
        unique_opens: 0,
        clicks: 0,
        unique_clicks: 0,
        unsubscribes: 0,
        bounces: 0,
        spam_reports: 0,
        blocks: 0,
        bounce_drops: 0,
        spam_report_drops: 0,
        unsubscribe_drops: 0,
        schedulings: 0,
      };
    }
  }
);
</script>
<template>
  <div class="dashboard-wrapper">
    <SidebarApp
      class="sidebar-app"
      :updateInbox="updateInbox"
      :inboxBusinessClients="inboxBusinessClients"
      @pageSelected="handlePageSelected"
    />
    <div class="dashboard-container">
      <h1 v-if="metaInfo[0] && metaInfo[0].company_name">
        Dashboard - {{ metaInfo[0].company_name.value }}
      </h1>
      <div class="select-wrapper">
        <label for="category">Selecione categoria</label>
        <select v-model="selectedCategory" name="category" id="category">
          <option v-for="(tag, index) in tags" :value="tag" :key="index">
            {{ tag }}
          </option>
        </select>
      </div>
      <div class="date-picker">
        <label for="start-date">Data inicial:</label>
        <input type="date" id="start-date" v-model="startDate" />
        <label for="end-date">Data final:</label>
        <input type="date" id="end-date" v-model="endDate" />
        <button @click="fetchData">Buscar métricas</button>
      </div>
      <KPIs :kpis="kpis" />
      <div class="chart-container">
        <BarChart :chartData="chartData" :chartOptions="chartOptions" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.sidebar-app {
  width: 17%;
}

.dashboard-wrapper {
  display: flex;
}

.dashboard-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  height: 100vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  color: #e60000;
}

.select-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.date-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

label {
  margin: 0 10px;
  color: #333;
}

input[type="date"] {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #ff4d4d;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #e60000;
}

.chart-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
