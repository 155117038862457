import { createRouter, createWebHistory } from "vue-router";
import InboxView from "../views/inbox/InboxView.vue";
import MailAnalytics from "../modules/inbox/views/MailAnalytics.vue";
import AdminAnalytics from "../modules/inbox/views/AdminAnalytics.vue";

import Automation from "@/views/automation/Automation.vue";
import AutomationAnalytics from "@/modules/automation/views/analytics/Analytics.vue";
import AutomationContacts from "@/modules/automation/views/contacts/Contacts.vue";
import AutomationCadences from "@/modules/automation/views/cadence/Cadence.vue";
import AutomationSegmentDetails from "@/modules/automation/views/segment/SegmentDetails.vue";

import AuthenticationForm from "@/views/authentication/AuthenticationForm.vue";

import { useAuthStore } from "../modules/auth/hooks/useAuth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: InboxView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Autenticação",
    component: AuthenticationForm,
    meta: { requiresAuth: false },
  },
  {
    path: "/admin",
    name: "Dashboard - Admin",
    component: AdminAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: "/inbox",
    name: "inbox - Main",
    component: InboxView,
    meta: { requiresAuth: true },
  },
  {
    path: "/inbox/:id",
    name: "inbox - Current Inbox",
    component: InboxView,
    meta: { requiresAuth: true },
  },
  {
    path: "/inbox/:id/dashboard",
    name: "inbox - Dashboard",
    component: MailAnalytics,
    meta: { requiresAuth: true },
  },
  {
    path: "/inbox/:id/messages/:messageId?",
    name: "inbox - Messages",
    component: InboxView,
    meta: { requiresAuth: true },
  },
  {
    path: "/automation",
    component: Automation,
    meta: { requiresAuth: true },
    children: [
      { path: "dashboard", component: AutomationAnalytics },
      { path: "contacts", component: AutomationContacts },
      { path: "cadences", component: AutomationCadences },
      {
        path: "/contacts/:segmentName",
        name: "SegmentDetails",
        component: AutomationSegmentDetails,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  const authStore = useAuthStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  const { data } = await authStore.fetchCurrentUser();

  if (data && to.path === "/login") {
    return next("/");
  }

  if (requiresAuth) {
    if (!data) {
      return next("/login");
    }
  }

  next();
});

export default router;
